<template>
    <div class="ContactForm">
        <div class="ContactForm_Inner Page__PaddingDefault Page__TextAlignCenter Page__Flex row M_Column Gap_xlg AlignVerticalMiddle">
            <div class="ContactForm__Calendly Page__FlexItem Page__FullHeight">
                <!-- <div class="ContactForm__CalendlyInner" id="ContactForm__CalendlyInner" style="height: 500px;"></div> -->
                <iframe src="https://calendly.com/stonehench/15min" width="100%" height="950" scrolling="no" frameborder="0"></iframe>
            </div>

            <div class="ContactForm__BasicContact Page__FlexItem">
                <div class="">
                    <h3 class="Page__Heading">Send Us a Message?</h3>
                    <p class="Page__Text">fill in this form to get in touch with us</p>
                </div>

                <div style="max-width: 800px; margin:auto;">
                    <form class="MarginTop_xlg" action="https://mailthis.to/sales@stonehench.co.uk" method="POST" encType="multipart/form-data" @submit="send_form_callback">
                        <div class="Page__Flex Gap_x4">
                            <input class="ContactForm__Input Page__FlexItem" name="name" placeholder="Name" />
                            <input class="ContactForm__Input Page__FlexItem" name="_subject" placeholder="Phone Number" />
                            <input class="ContactForm__Input Page__FlexItem" name="_replyto" placeholder="Email" />
                        </div>

                        <div class="Page__Flex Gap_x4 MarginTop_x5">
                            <textarea class="ContactForm__Input Page__FlexItem" name="message" placeholder="Name" />
                        </div>

                        <input type="hidden" name="_after" value="https://stonehench.co.uk">
                        <input type="hidden" name="_honeypot" value="">
                        <input type="hidden" name="_confirmation" value="">

                        <div class="MarginTop_xlg">
                            <input type="submit" value="Send Email" class="Page__ConvertButton" />
                            <p class="Page__Text MarginTop_x3">
                                By entering your information and submitting this form you accept our <router-link :to="{ name: 'PrivacyPolicy' }" class="ContactForm__Link">privacy policy</router-link> and agree to be contacted
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: 'ContactForm',

    created() { window.addEventListener("message", this.set_up_calendly_listener) },
    destroyed() { window.removeEventListener("message", this.set_up_calendly_listener) },
    mounted() { this.add_calendly_widget() },

    methods:
    {
        send_form_callback: async function()
        {
            fbq('track', 'SubmitApplication', { channel: "form" });
        },


        set_up_calendly_listener: async function(e)
        {
            const isCalendlyEvent = (e) => { return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0; };
            
            if(isCalendlyEvent(e))
            {
                if (e.data.event === 'calendly.event_scheduled')
                {
                    // Navigate to thank you page for the google conversion..
                    this.$router.push({ name: "OrderComplete" })
                }

                /* Example to get the name of the event */
                console.log("Event name:", e.data.event);
                
                /* Example to get the payload of the event */
                console.log("Event details:", e.data.payload);
            }
        },

        add_calendly_widget()
        {
            Calendly.initInlineWidget({
                url: 'https://calendly.com/stonehench/15min',
                parentElement: document.getElementById('ContactForm__CalendlyInner'),
                prefill: {},
                utm: {}
            });
        }
    }
}
</script>

<style lang="scss">


    ._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv
    {
        margin-top: 0 !important;
    }

    .ContactForm__Input
    {
        background: var(--color_text_contrast);
        border: none;
        font-family: $font_family_base;
        padding: 8px 12px;

        border-radius: 6px;

        border: 1px solid var(--color_background_tertiary);

        background-color: rgba(102,102,102,.15);
        border-color: rgba(81, 81, 100, 1) !important
    }
</style>