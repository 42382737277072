<template>
    <div class="MainHeader">
        <div class="MainHeader__Inner Page__Flex column">
            <a  :href="'tel:' + $store.getters.metadata('mobileNumber')" 
                class="Page__Text Page__MTextAlignCenter MainHeader__PhoneUs MarginBottom_x2 Page__MobileOnly">
                    Call Us Now: {{ $store.getters.metadata('mobileNumber') }}</a>

            <div class="MainHeader__LogoContainer Page__FlexNoShrink Page__Flex M_Row Gap_x3">
                <router-link :to="{ name: 'Home' }" class="MainHeader__Logo Page__Flex row">
                    <img src="~@/assets/images/logo.png" height="100%" style="border-radius: 100%"/>
                    <h1 class="Page__Heading MainHeader__LogoHeading">Stone<br/>Hench</h1>
                </router-link>
                <div class="MainHeader__MenuButton Page__FlexNoShrink Page__MobileOnly" @click="is_menu_open = !is_menu_open">
                    <i class="fa-solid fa-bars" v-if="!is_menu_open"></i>
                    <i class="fa-regular fa-circle-xmark" style="font-size: 30px" v-if="is_menu_open"></i>
                </div>
            </div>

            <div class="MainHeader__Links Page__Flex Column MarginTop_xlg Page__FlexItem Page__MDisplayNone" :class="{ 'showLinks': is_menu_open }">

				<router-link class="MainHeader__Link MarginBottom_x9" :to="{ name: item.name }" v-for="item in $store.state.routes" :key="item.name" @click.native="is_menu_open = false" v-show="!item.meta || !item.meta.hide_menu">
					<span v-bind:class="{ 'MainHeader__LinkStrong': item.meta.popular }">{{item.name}}</span>
                    <span class="MainHeader__LinkPopular MarginLeft_x2" v-if="item.meta.popular">
                        <i class="fa-regular fa-fire-flame-curved" style="font-size: 0.87em"></i> 
                        popular!
                    </span>
				</router-link>

                <div class="Page__FlexItem"></div>
                <router-link class="Page__ConvertButton" :to="{name: 'Contact' }">Get a Quote</router-link>
                <!-- <div class="Page__ConvertButton MarginTop_x2">Access Brochure</div> -->
            </div>
        </div>
    </div>
</template>

<script>

    export default 
    {
        name: 'MainHeader',
        data()
        {
            return {
                is_menu_open: false
            }
        }
    }
</script>


<style lang="scss" scoped>

    .MainHeader
    {
        height: 100%;

        .MainHeader__Links
        {
            .MainHeader__Link
            {
                .MainHeader__LinkStrong { font-weight: 500; }

                text-decoration: none;
                &>span:first-child { text-decoration: underline; }
            }

            .MainHeader__LinkPopular
            {
                background: #eee;
                text-decoration: none !important;
                font-size: 0.85em;
                padding: 3px 5px;
                border-radius: 12px;
            }

            @media (max-width: $media_query_mobile)
            {
                &:not(.showLinks) { display: none; }
            }

        }



        .MainHeader__Inner
        {
            height: 100%;
            padding: 30px;
            width: 230px;
            @media (max-width: $media_query_mobile) 
            { 
                width: 100%; 
                padding: 15px 15px; 

                box-shadow: unset;
                border-bottom: 1px solid #0007;
            }

            background: #fbfbfb;
            box-shadow: 0 0 11px 1px #e1e1e1;

            .MainHeader__PhoneUs
            {
                padding: 3px;
                background: var(--color_background_tertiary);
                color: var(--color_text_contrast);
                text-decoration: none;
                margin: -18px -30px;
                margin-bottom: 20px;
            }

            .MainHeader__MenuButton
            {
                background: #eee;
                border-radius: 100%;
                position: relative;
                height: 60px;
                width: 60px;
                 
                &>i { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 17px; }
            }

            .MainHeader__LogoContainer
            {
                .MainHeader__Logo
                {
                    background: #eee;
                    border-radius: 25px;
                    height: 60px;
                    width: 92%;
                }


                .MainHeader__LogoHeading
                {
                    font-size: 19px;
                    line-height: 1.1;
                    padding: 10px 10px;
                }
            }
        }
    }

</style>