<template>
    <div class="Testimonials">
        <div class="Testimonials__Inner Page__PaddingDefault">
            <div class="MarginBottom_xlg Page__TextAlignCenter">
                <h3 class="Page__Heading">Praise From Our Clients</h3>
            </div>

            <div class="Page__Flex row M_Column" style=" margin: auto;">
                <div class="WhyChoseUs__Item Page__FlexItem" v-for="item in data" :key="item._id">
                    <div class="Testimonials__Stars Page__Flex M_Row Gap_x1">
                        <div class="Testimonials__Star"><i class="fa-solid fa-star"></i></div>
                        <div class="Testimonials__Star"><i class="fa-solid fa-star"></i></div>
                        <div class="Testimonials__Star"><i class="fa-solid fa-star"></i></div>
                        <div class="Testimonials__Star"><i class="fa-solid fa-star"></i></div>
                        <div class="Testimonials__Star"><i class="fa-solid fa-star"></i></div>
                    </div>

                    <div class="WhyChoseUs__ItemInner Page__Flex row M_Row Gap_x6 AlignVerticalMiddle MarginTop_x5">
                        <!-- <div class="WhyChoseUs__ItemNumber noselect Page__FlexNoShrink">
                            <div><i class="fa-regular fa-star"></i></div>
                        </div> -->
                        <div>
                            <h5 class="Page__Heading WhyChoseUs__ItemHeading MarginBottom_x3">{{ item.name }}</h5>
                            <p class="Page__Text WhyChoseUs__ItemText">{{ item.text }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default 
{
    name: "Testimonials",
    props: [],		
    components: { },

    data()
    {
        return { 
            data: [
                { _id: 0, name: "John Bowe", text: "Very quick turnaround, refreshingly friendly and helpful team, and great quality stone. Would definitely recommend ✨" },
                { _id: 1, name: "Amit S.", text: "The team at stone hench have handled all of our needs. Would Recommend" },
                { _id: 2, name: "Aaliah P.", text: "Great services, thank you to stone hench for transforming our kitchen" },
                { _id: 3, name: "Anna Cooper", text: "thank you, very happy with my new quartz worktop" },
            ]
        }
    },

    mounted() { },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>

    .WhyChoseUs__Item
    {
        padding: 20px;

        position: relative;
        border: 1px solid #000;
        margin: 0px -1px;

        @media (min-width: $media_query_mobile) 
        { 
            &:not(:last-of-type) { border-right: 0; } 
            &:first-of-type { border-radius: var(--border_radius_main) 0px 0px var(--border_radius_main); } 
            &:last-of-type { border-radius: 0px var(--border_radius_main) var(--border_radius_main) 0px; } 
        }
        @media (max-width: $media_query_mobile) 
        {
            &:not(:last-of-type) { border-bottom: 0; }
            &:first-of-type { border-radius: var(--border_radius_main) var(--border_radius_main) 0px  0px; } 
            &:last-of-type { border-radius: 0px 0px var(--border_radius_main) var(--border_radius_main); } 
        }

        .Testimonials__Stars
        {
            .Testimonials__Star
            {
                background: #eee;
                height: 27px;
                width: 27px;
                border-radius: 6px;
                position: relative;
                border: 1px solid #000;

                &>i
                {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: var(--color_background_button);
                }
            }
        }

        .WhyChoseUs__ItemNumber
        {
            display: inline-block;
            margin-bottom: 30px;
            position: relative;
            top: 0;
            left: 0;
            background: #eee;
            height: 60px;
            width: 60px;
            border-radius:505px;

            &>div
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
            }
        }
    }

</style>