// define a mixin object
import Vue from 'vue'

import axios from "axios";
import store from '../store'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'


export const HTTPRequest =
{
	data: function ()  {
		return {
			getting_visitor_id: false,
		}
	},

	methods:
	{
		request: function (type, url, options, data)
		{
			var vm = this
			return new Promise(async function (resolve)
			{
				try
				{
					if (!store.state.headers['x-visitor-id'] && !vm.getting_visitor_id)
					{
						if (Vue.prototype.$cookie.get('x-visitor-id'))
						{
							store.commit('setHeader', { name: 'x-visitor-id', content: Vue.prototype.$cookie.get('x-visitor-id') })
						}
						else
						{
							console.log("Getting Visitor ID")
							vm.getting_visitor_id = true
							const fpPromise = FingerprintJS.load({ apiKey: '1PrYvBYkok3Q4wf42Qgd', region: 'eu' })
							console.log(fpPromise)
							const fp = await fpPromise
							const fpResult = await fp.get()
	
							// window.analytics.ready(function() 
							// {
							// 	const anonId = window.analytics.user().anonymousId();
							// 	store.commit('setHeader', { name: 'x-anonymous-id', content: anonId })
							// });
	
							Vue.prototype.$cookie.set('x-visitor-id', fpResult.visitorId)
							store.commit('setHeader', { name: 'x-visitor-id', content: fpResult.visitorId })
						}
					}
			
					if (!url.startsWith('https://discord.com'))
						for (const key in store.state.headers)
							axios.defaults.headers.common[key] = store.state.headers[key]

					let result = await axios.request({ method: type, url: url, baseURL: process.env.VUE_APP_API_URL, data: data, ...options });

					resolve({ data: result.data.content, error: null, status: result.data.status })
				}
				catch (error)
				{
					resolve({ data: error, error: error })
				}
			})
		},


        api: async function(type, resource, options = {}, data = {})
        {
			let results = this.request.apply(this, [type, process.env.VUE_APP_API_URL + resource, options, data])
			return results
        }
	}
}
