<template>
    <div class="ProjectsList Page__PaddingDefault Page__SecondaryBackground">
        <div class="ProjectsList__Repeating MarginBottom_xlg" ref="repeating">
            <h3 class="Page__Heading adasdasd">Recent Projects</h3>
        </div>

        <div class="Page__Flex Gap_xmd MarginTop_x5">
            <div class="ProjectsList__Inner Page__Flex column Page__FlexItem" v-for="material in data" :key="material.id">
                <div class="RecentProjects__Image cover" v-bind:style="{ background: 'url(' + material.thumbnail.url + ')' }"></div>

                <div class="ProjectsList__Content">
                    <div class="ProjectsList__ContentTags">
                        <p class="Page__Text small lighter Page__DisplayInlineBlock MarginBottom_x3">
                            <i class="fa-solid fa-location-dot"></i> {{ material.locationText }} </p>
                        <p class="Page__Text small lighter Page__DisplayInlineBlock MarginLeft_x3">
                            <i class="fa-solid fa-circle-dot"></i> {{ material.category }} </p>
                    </div>

                    <h5 class="ProjectsList__ContentHeading Page__Heading MarginTop_x3">{{ material.title }}</h5>
                    <p class="ProjectsList__ContentText Page__Text MarginTop_x1">{{ material.descriptionShort }}</p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default 
{
    name: "RecentProjects",
        
        
    data()
    {
        return {
            data: null
        }
    },

    beforeMount()
    {
        this.http_fetch()
    },

    mounted()
    {
        // var text = 'Recent Amazing Stone Projects';
        // var canvas = document.createElement("canvas");
        // var fontSize = 24;
        // canvas.setAttribute('height', fontSize);
        // var context = canvas.getContext('2d');
        // context.fillStyle    = 'rgba(0,0,0,0.2)';
        // context.font         = fontSize + 'px sans-serif';
        // context.fillText(text, 0, fontSize);

        // this.$refs.repeating.style.backgroundImage = "url(" + canvas.toDataURL("image/png")+ ")"
    },

    methods:
    {
        async http_fetch()
        {
            const query = this.gql`
            {
                projects {
                    id
                    title
                    descriptionShort
                    publishedAt
                    stage
                    urlSlug
                    locationText
                    category
                    thumbnail {
                        url()
                    }
                }
            }`

            this.data = await this.try_fetch(query)
        }
    }
}
</script>


<style scoped lang="scss">
    
    .adasdasd
    {
        font-style: italic;
        padding: 3px;
        text-align: center;
        // background: #fff7;
        padding-top: 18px;
    }

    .RecentProjects__Image
    {
        height: 250px;
        width: 100%;

        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;

        &.cover { background-size: cover !important; }
        &.contain { background-size: contain !important; }
    }

    .ProjectsList__Repeating
    {
        height: 50px;
        width: 100%;
    }

    .ProjectsList__Inner
    {
        border: 2px solid var(--color_border_main);
        border-radius: 6px;
        overflow: hidden;
        background: #f5f5f5;
        box-shadow: 0 0 12px 1px #0000000a;
        
        .ProjectsList__Content
        {
            padding: 15px;


            .ProjectsList__ContentTags
            {
                p, p>i
                {
                    font-size: 13px;
                    color: #0009;
                }
            }

            .ProjectsList__ContentHeading
            {
                font-weight: 500;
            }

            .ProjectsList__ContentText
            {
                text-align: justify;
            }
        }

        .ProjectsList_Thumbnail
        {
            height: 400px;
            @media (max-width: $media_query_mobile)
            {
                height: 250px;
            }

            width: 100%;
            border-bottom: 2px solid var(--color_border_main);
            
            &>div
            {
                background: url("");
                background-size: cover !important;
            }
        }
    }

</style>