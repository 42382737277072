import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from './views/HomeView.vue'

const ContactView = () => import('./views/ContactView.vue')
const PrivacyPolicy = () => import('./views/PrivacyPolicy.vue')
const SustainabilityView = () => import('./views/SustainabilityView.vue')

const MaterialView = () => import('./views/materials/MaterialView.vue')
const MarbleView = () => import('./views/materials/MarbleView.vue')
const GraniteView = () => import('./views/materials/GraniteView.vue')

const OrderView = () => import('./views/OrderView.vue')
const ThankView = () => import('./views/ThankView.vue')


const DEFAULT_TITLE = 'Stone Hench';


Vue.use(VueRouter)

const routes = [
  { path: '/',                    name: 'Home', component: HomeView, meta: { title: "Stone Hench | Home" } },

  { path: '/materials/quartz',    name: 'Quartz',  component: MaterialView, meta: { title: "Stone Hench | Quartz", popular: true } },
  { path: '/materials/granite',   name: 'Granite', component: MaterialView, meta: { title: "Stone Hench | Granite" } },
  { path: '/materials/marble',    name: 'Marble',  component: MaterialView, meta: { title: "Stone Hench | Marble" } },
  
  // { path: '/materials/other', name: 'Home', component: HomeView, meta: { title: "Stone Hench | Home" } },
  { path: '/materials/sustainability', name: 'Sustainability', component: SustainabilityView, meta: { title: "Stone Hench | Sustainability" } },
  { path: '/contact',             name: 'Contact', component: ContactView, meta: { title: "Stone Hench | Contact" } },
  { path: '/legal/privacy-policy',name: 'PrivacyPolicy', component: PrivacyPolicy, meta: { title: "Stone Hench | Privacy Policy", hide_menu: true, hide_footer: true } },
  
  { path: '/order/:material/:colour', name: 'Order', component: OrderView, meta: { title: "Stone Hench | Get a Quote" } },
  { path: '/order/thank-you',         name: 'OrderComplete', component: ThankView, meta: { title: "Stone Hench | Form Submitted", hide_menu: true, hide_footer: true } },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) 
	{
		let el = document.getElementById('App__Content')
		if (el)
			el.scrollTop = 0

			window.scrollTo(0, 0);
		return { x: 0, y: 0, selector: '#App__Content' };
	},
})


router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
