import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        vendor: "Stone Hench",
        headerHeight: 0,
        metadata: {},
        routes: [],
        headers: []
    },

    mutations: 
    {
		setHeader (state, header) { state.headers[header.name] = header.content },

        headerHeight (state, n)
        {
            state.headerHeight = n
        },
        setHeaderHeight (state, height)
        {
            state.headerHeight = height
        },

        metadata(state, metadata)
        {
            state.metadata = metadata
        },

        resetRoutes(state) { state.routes = [] },
        addRoute(state, route)
        {
          state.routes.push(route)
        }
    },

    getters: {
        metadata: (state) => (propertyName) =>
        {
            return state.metadata[propertyName] || 'Fetching Data'
        }
    },

    actions: {
    },
    modules: {
    }
})
