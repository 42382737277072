import { GraphQLClient, gql } from 'graphql-request'
import Vue from 'vue'

Vue.mixin(
{
    created()
    {
        this.$client = new GraphQLClient('https://api-eu-west-2.hygraph.com/v2/cl70oirzm1lym01uhdbg4fnbn/master')
        // client.setHeader('authorization', 'Bearer MY_TOKEN')
    },
  
    data()
    {
        return {
            $client: null,
            gql: gql
        }
    },
  
    methods:
    {
        async try_fetch(query)
        {
            let data = await this.$client.request(query)
            data = data[Object.keys(data)[0]]
  
            return data
        }
    },
  
    computed: 
    {
        $headerHeight: {
            get: function () { return globalData.$data.$headerHeight },
            set: function (newVal) { globalData.$data.$headerHeight = newVal; }
        },
        $hygraph: { get: () => { return "https://api-eu-west-2.hygraph.com/v2/cl70oirzm1lym01uhdbg4fnbn/master" } }
    }
})
