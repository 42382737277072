import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'

import VeeValidate from 'vee-validate';
import VueSanitize from "vue-sanitize";
import VueCookie from 'vue-cookie'

import hygraph from '@/mixins/hygraph'

import '@/assets/scss/_common.scss'
import '@/assets/fa/css/all.css'
import '@/assets/fa/css/sharp-regular.css'
import '@/assets/fa/css/sharp-solid.css'

import PageInput from "@/components/PageInput"
import { InputModelConverter } from "@/mixins/InputModelConverter"
import InteractiveButton from "@/components/InteractiveButton"
import { HTTPRequest } from '@/mixins/HTTPRequest';
import posthog from 'posthog-js'

let defaultOptions = {
  allowedTags: ['a', 'b', 'h5', 'h6', 'strong', 'italic', 'p', 'div', 'br'],
  allowedAttributes: {
    'a': [ 'href' ]
  }
};

posthog.init('phc_UfOJJgcjoIf1cCMOH8JKHbGbnfDmgirIUz2Pd63OqDJ', { api_host: 'https://eu.posthog.com' })


Vue.config.productionTip = false
Vue.component('PageInput', PageInput)
Vue.component('InteractiveButton', InteractiveButton)

Vue.mixin(InputModelConverter)
Vue.mixin(InputModelConverter)
Vue.mixin(HTTPRequest)

Vue.use(VueCookie)
Vue.use(VeeValidate);
Vue.use(VueSanitize, defaultOptions);

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
