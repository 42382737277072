<template>
	<div class="App">
		<div class="App__Inner" :style="{ height: window_inner_height + 'px' }">
			<div class="App__Heading">
				<main-header />
			</div>
			<div class="App__Content" id="App__Content">
				<router-view />

				<div class="App__Footer">
					<main-footer />
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import MainHeader from '@/components/Layout/MainHeader.vue'
import MainFooter from '@/components/Layout/MainFooter.vue'

export default 
{
	name: 'App',
	components: { MainHeader, MainFooter },

	data()
    {
        return {
            sections: [],
            current_section_index: 0,
            height: 0,
			window_inner_height: 0,
        }
    },


	destroyed() { window.removeEventListener("resize", this.get_inner_height); },
	created()
    {
		this.get_inner_height()
 		window.addEventListener("resize", this.get_inner_height);
        this.http_fetch()
		this.parse_routes()
    },


	methods:
    {
        http_fetch: async function()
        {
            const query = this.gql`
            {
                metadata(where: {id: "cl8f3wu5q92wr0amkxwlrwuw0"}) {
                    id
                    mobileNumber
                    officeNumber
                    whatsappNumber
                    companyAddress
                    companyName
                    companyNumber
                    salesEmail
                    openingTimes
                }
            }`

            let data = await this.try_fetch(query)
            this.$store.commit('metadata', data)
        },



		parse_routes: async function()
		{
			this.$store.commit('resetRoutes')
			this.$router.options.routes.forEach(route =>
			{
				this.$store.commit('addRoute', {
					name: route.name, 
					path: route.path,
					meta: route.meta
				})
			})
		},


		get_inner_height: async function()
		{
			this.window_inner_height = window.innerHeight
		}
	}

}
</script>


<style lang="scss">
	.App__Inner
	{
		height: 100vh;
	}


	.App__Inner
	{
		display: flex;
		flex-direction: row;

		@media (max-width: $media_query_mobile) { flex-direction: column; }

		& > .App__Content
		{
			height: 100%;
			overflow-y: scroll;
			flex: 1;
		}
	}
</style>
