<template>
    <div class="MainFooter">
        <div class="MainFooter__Inner Page__Flex Gap_xlg Page__PaddingDefault">
            <div class="MainFooter__Column Page__FlexItem">
                <div class="MainHeader__LogoContainer">
                    <div class="MainHeader__Logo"><img src="" style="height: 70px" /></div>
                    <p class="Page__Text invert MarginTop_x7">{{ $store.getters.metadata('mobileNumber') }}</p>
                    <p class="Page__Text invert">{{ $store.getters.metadata('salesEmail') }}</p>
                    <p class="Page__Text invert MarginTop_x3" v-html="$store.getters.metadata('companyAddress')"></p>
                </div>
            </div>
            <div class="MainFooter__Column">
                <h5 class="Page__Heading invert MarginBottom_x7">Pages</h5>
                <router-link class="MainHeader__ContentItem Page__DisplayBlock MarginBottom_x2" :to="{ name: item.name }" v-for="item in $store.state.routes" :key="item.name" v-show="!item.meta || !item.meta.hide_menu">
                    <div class="Page__Text invert MainHeader__ContentItemText">{{ item.name }}</div>
                </router-link>
            </div>

            <div class="MainFooter__Column" style="max-width: 200px;">
                <h5 class="Page__Heading invert MarginBottom_x7">About Us</h5>
                <p class="Page__Text invert">Trusted and reliable stone product supplier based in London.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default 
    {
        name: 'MainFooter',

        data()
        {
            return { 

            }
        },

    }
</script>


<style lang="scss" scoped>
    .MainFooter
    {
        .MainFooter__Inner
        {
            background: var(--color_background_contrast);
            background: linear-gradient(90deg,#f9f4f0,rgba(249,244,240,.34902)),url(~@/assets/images/footer.jpg);
            color: var(--color_text_contrast);

            &, &>* *
            {
                color: var(--color_text_main);
            }
        }
    }
</style>