<template>
    <div class="MaterialsAvailable Page__PaddingDefault small">
        <div class="MaterialsAvailable__HeadingContainer Page__Flex row M_row AlignVerticalMiddle Gap_x9 M_MarginBottom_x0 MarginBottom_x5" ref="repeating">
            <h3 class="Page__Heading MaterialsAvailable__Heading">Our Materials</h3>
            <div class="MaterialsAvailable__HeadingFiller Page__FlexItem"></div>
        </div>

        <div class="Page__Flex Gap_xmd">
            <div class="MaterialsAvailable__Inner Page__Flex Page__FlexItem column" v-for="material in data" :key="material.id">
                
                <!-- Upper Part (Content) -->
                <div class="Page__Flex" v-bind:class="{ row: compressed || material.name.toLowerCase() != 'quartz' }">
                    <!-- Left Part (image) -->
                    <div class="MaterialsAvailable_Thumbnail Page__DIFullHeight" v-bind:class="{ compressed: compressed || (material.name.toLowerCase() != 'quartz' && is_mobile) }" style="flex-shrink: 0">
                        <div class="MaterialsAvailable_ThumbnailInner contain" style="flex-shrink: 0" v-bind:style="{ background: 'url(' + material.thumbnail.url + ')' }"></div>
                    </div>

                    <!-- Right Part (Title & description) -->
                    <div class="MaterialsAvailable__Content Page__Flex column AlignHorizontalMiddle" v-bind:class="{ compressed: compressed || (material.name.toLowerCase() != 'quartz' && is_mobile) }">
                        <h4 class="Page__Heading">
                            {{ material.name }}
                            <span class="MaterialsAvailable__ContentPopular MarginLeft_x2" v-if="material.name == 'quartz'">
                                <i class="fa-regular fa-fire-flame-curved" style="font-size: 0.87em"></i> popular!
                            </span>
                        </h4>
                        <p class="Page__Text M_MarginTop_x3" v-if="!compressed && (material.name.toLowerCase() == 'quartz' || !is_mobile)">{{ material.descriptionShort }}</p>
                        <router-link class="Page__Link M_MarginBottom_x7" v-else :to="{ name: material.name.capitalize() }">
                            Explore {{ material.name }} ⟶
                        </router-link>
                    </div>
                </div>

                <!-- Lower Part (Links) -->
                <div class="MaterialsAvailable__Links MarginTop_x7 Page__Flex row M_Column Gap_x9 M_Gap_x0" v-if="!compressed && (material.name.toLowerCase() == 'quartz' || !is_mobile)">
                    <div class="MarginBottom_x3">
                        <router-link class="Page__Link" :to="{ name: material.name.capitalize() }">
                            <i class="fa-fw fa-sharp fa-regular fa-sparkles"></i>
                            Explore <span>{{ material.sample_count }}</span> {{ material.name }} Samples ⟶
                        </router-link>
                    </div>
                    <router-link class="Page__Link M_MarginBottom_x7" :to="{ name: material.name.capitalize() }">
                        <i class="fa-fw fa-sharp fa-regular fa-clipboard-list-check"></i>
                        Start Your Order ⟶
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
export default 
{
    name: "MaterialsAvailable",
    props: ['compressed'],
        
    data()
    {
        return {
            data: null,
            is_mobile: false
        }
    },

    created() {
        this.onResize()
    window.addEventListener('resize', this.onResize)
    },

    beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    },

    beforeMount()
    {
        this.http_fetch()
    },

    mounted()
    {
        // var text = 'Materials Materials Materials';
        // var canvas = document.createElement("canvas");
        // var fontSize = 24;
        // canvas.setAttribute('height', fontSize);
        // var context = canvas.getContext('2d');
        // context.fillStyle    = 'rgba(0,0,0,0.2)';
        // context.font         = fontSize + 'px sans-serif';
        // context.fillText(text, 0, fontSize);

        // this.$refs.repeating.style.backgroundImage = "url(" + canvas.toDataURL("image/png")+ ")"
    },

    methods:
    {
        async http_fetch()
        {
            const query = this.gql`
            {
                materials {
                    id
                    name
                    descriptionShort
                    publishedAt
                    stage
                    urlSlug
                    thumbnail {
                        url
                    }
                }
            }`

            this.data = await this.try_fetch(query)

            for (let i=0; i<this.data.length; i++)
            {

            }

            this.data.forEach(async item => {
                const q2 = this.gql`
                {
                    samplesConnection(where: {material: {id: "${item.id}"}}) {
                        aggregate {
                        count
                        }
                    }
                }`

                let result = await this.try_fetch(q2)
                this.$set(item, 'sample_count', result.aggregate.count)
            })
        },

        onResize() 
        {
            if (window.innerWidth > 700) 
            {
                this.is_mobile = false
            } else 
            {
                this.is_mobile = true
            }
  }
    }
}
</script>


<style scoped lang="scss">
    
    .adasdasd
    {
        font-style: italic;
        text-align: center;
    }

    .MaterialsAvailable__Repeating
    {
        height: 50px;
        width: 100%;
    }

    .MaterialsAvailable__Heading
    {
        margin: 0;
        text-transform: uppercase;
        font-size: 25px;
    }

    .MaterialsAvailable__HeadingFiller 
    {
        border-bottom: 2px solid #0007;
    }

    .MaterialsAvailable__Inner
    {
        background: var(--color_background_secondary);
        // border-radius: var(--border_radius_main);
        border: 2px solid var(--color_border_main);
        overflow: hidden;

        .MaterialsAvailable__Content
        {
            padding: 25px;
            &.compressed { padding: 15px; padding-right: 0; }

            .MaterialsAvailable__ContentPopular
            {
                background: #fff;

                &, &>i
                {
                    color: var(--color_background_button);
                    text-decoration: none !important;
                    font-size: 0.55em;
                    text-transform: uppercase;
                }

                padding: 2px 10px;
                border-radius: 25px;
                border: 1px solid var(--border_color_input);
                vertical-align: 2px;
            }
        }

        .MaterialsAvailable__Links
        {
            padding: 0px 30px;
            

            .Page__Link 
            { 
                text-transform: uppercase;
                color: blue; 
                text-decoration: none;
                letter-spacing: 1px;
            }
        }

        .MaterialsAvailable_Thumbnail
        {
            height: 200px;
            width: 200px;
            border-right: 2px solid var(--color_border_main);
            // border-radius: 8px;

            &.compressed 
            { 
                width: 100px; height: 100px; 

                &>.MaterialsAvailable_ThumbnailInner
                {
                    height: 100px;
                    width: 100px;
                    background-size: 180px !important;
                }
            }
            
            @media (max-width: $media_query_mobile)
            {
                width: 100%; 
                border-right: none;    
                border-bottom: 2px solid var(--color_border_main);

            }

            &>.MaterialsAvailable_ThumbnailInner
            {
                background-position: center !important;
                background-repeat: no-repeat !important;
                background: url("");

                height: 200px;
                background-size: 650px !important;
                // border-radius: var(--border_radius_main);
            }
        }
    }

</style>