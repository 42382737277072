<template>
    <div class="Hero" v-bind:style="{ ...(heightOverride && { height: heightOverride }) }">
        <div class="Hero__Inner"
             v-bind:style="{ 
                background: 'radial-gradient(circle at center center, rgb(0 0 0 / 35%) 30%, rgb(0 0 0 / 35%), rgb(0 0 0 / 35%)) 0% 0% / cover, url(' + ((externalUrl) ? externalUrl : require(`@/assets/images/${this.backgroundUrl}`)) + ')', 
                backgroundSize: 'cover'
            }">
            <div class="Hero__Content">
                <hr class="Divider"/>
                <slot></slot>
                <h1 class="Page__Heading MarginBottom_x3">{{ heading || 'Unnamed Page' }}</h1>
                <p class="Page__Text Hero__Text" style="max-width: 450px" v-if="subTitle">{{ subTitle }}</p>

                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default 
{
    name: "Hero",
    components: {},
    props: ['backgroundUrl', 'heading', 'subTitle', 'heightOverride', 'externalUrl' ],

    data()
    {
        return { 

        }
    },

    mounted() { },


    methods:
    {

    }
}
</script>


<style lang="scss" scoped>
    .Hero
    {
        width: 100%;
        height: 700px;

        @media (max-width: $media_query_mobile)
        {
            margin: 15px;
            width: calc(100% - 30px);
        }

        .Hero__Inner
        {
            height: 100%; width: 100%;
            position: relative;
            background-size: cover !important;
            background-position: center !important;
            background-attachment: fixed !important;
            .Hero__Content
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;

                &, & * *, &>p, &>.Page__Heading { color: var(--color_text_contrast); }


                .Hero__Text { margin: auto; font-size: 1.1em; }

                @media (max-width: $media_query_mobile)
                {
                    width: 85%;
                    text-align: left;

                    &>.Page__Heading { line-height: 1; color: #ffffffb3; }
                    &>.Hero__Text { font-size: 0.9em; padding-right: 15%; }
                }


            }
        }
    }



    .Divider
    {
        background: linear-gradient(45deg,transparent 10px,currentColor 11px,transparent 12px) 0 0,linear-gradient(-45deg,transparent 10px,currentColor 11px,transparent 12px) 0 0!important;
        height: 15px!important;
        background-repeat: repeat!important;
        background-size: 20px 20px!important;
        min-height: initial!important;
        border-color: currentColor!important;
        border-width: 0!important;
        color: #fff!important;

        @media (max-width: $media_query_mobile)
        {
            margin-bottom: 20px;    
            width: 35%;
        }
    }
</style>