<template>
	<div class="View__Home">
		<hero 
			backgroundUrl="stock-default_alt.jpg" 
			heading="Transform Your Home" 
			subTitle="Our natural stone kitchen worktops will transform any kitchen into a beatiful and living place - scroll down to find out more"
			heightOverride="490px">
			<template slot="content">
				<div class="HomeHero__Features MarginTop_x9 Page__Flex M_Column row M_Gap_x1 wrap AlignHorizontalMiddle Gap_x5">
					<div class="HomeHero__Feature Page__Flex Row Gap_x3 AlignVerticalMiddle">
						<i class="fa-fw fa-sharp fa-regular fa-tarp HomeHero__Icon"></i>
						<p class="Page__Text HomeHero__Text">Kitchen Worktops</p>
					</div>

					<div class="HomeHero__Feature Page__Flex Row Gap_x3 AlignVerticalMiddle">
						<i class="fa-fw fa-sharp fa-regular fa-ruler-combined HomeHero__Icon"></i>
						<p class="Page__Text HomeHero__Text">Precise Templating</p>
					</div>

					<div class="HomeHero__Feature Page__Flex Row Gap_x3 AlignVerticalMiddle">
						<i class="fa-fw fa-sharp fa-regular fa-truck-front HomeHero__Icon"></i>
						<p class="Page__Text HomeHero__Text">Delivery and fitting within 14 days</p>
					</div>

					<div class="HomeHero__Feature Page__Flex Row Gap_x3 AlignVerticalMiddle">
						<i class="fa-fw fa-sharp fa-regular fa-swatchbook HomeHero__Icon"></i>
						<p class="Page__Text HomeHero__Text">Over 300 Slabs & Colours In Stock</p>
					</div>


				</div>
				<div class="HomeHero__Features M_MarginTop_xmd MarginTop_xlg Page__Flex row M_Row Gap_x3 AlignVerticalMiddle AlignHorizontalMiddle">
					<i class="fa-fw fa-sharp fa-regular fa-chevron-down HomeHero__Icon"></i>
					<p class="Page__Text HomeHero__Text">Scroll To Find Out More</p>
				</div>
			</template>
		</hero>
		<materials />
		<recent-projects />
		<testimonials />
		<contact-form/>
		<materials :compressed="true" />
	</div>
</template>

<script>
    import Hero from '@/components/Layout/Hero.vue'
	import Materials from '@/components/Views/Home/Materials.vue'
	import RecentProjects from '@/components/Views/Home/RecentProjects.vue'
	import Testimonials from '@/components/Views/Home/Testimonials.vue'
	import ContactForm from '@/components/Views/Contact/ContactForm.vue'

	export default 
	{
		name: 'HomeView',       
		components: { Hero, Materials, RecentProjects, Testimonials, ContactForm },
	}
</script>

<style lang="scss" scoped>
	.HomeHero__Icon
	{
		font-size: 0.9em;
	}

	.HomeHero__Text
	{ font-size: 0.9em; color: #ffffffb3 !important; }
</style>