<template>
	<div class="Page__InputContainer">

		<!-- Basic text input, with optional inline submission -->
		<template v-if="!inputType || inputType == 'text' || inputType == 'number' || inputType == 'otp' || inputType == 'tel'">

			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<div class="Page__InputFollowerText" v-bind:style="{ 'left': input_width + 'px' }" v-if="input_width > 0 && internalInputModel && hasFollowerText">
					<slot name="followerText"></slot>
				</div>
				<div class="Page__InputOTPOVerlay Page__Flex" v-if="inputType == 'otp'">
					<div class="Page__InputOTPOverlayItem Page__Input" v-for="n in maxlength" :key="n">
						<template v-if="internalInputModel">{{ internalInputModel.charAt(n-1) }}</template></div>
				</div>
				<input :min="min" :max="max"
					class="Page__Input" 
					:type="inputType || 'text'" 
					:placeholder="placeholderText" 
					:value="internalInputModel" 
					:maxlength="maxlength"
					:autocomplete="autocomplete"
					@input="onInputValueChange"
					v-bind:class="{ 'PageInput__Loading': status == 2, 'PageInput__Success': status == 3, 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase, 'PageInput__Transparent': inputType == 'otp' }"
					@keyup.enter="enterHandler" ref="inputBox"
				/>
			</div>


			<div class="Page__Input_StateContainer">
				<template v-if="status == 2">
					<i class="fas fa-spinner fa-spin"></i>
					Validating
				</template>

				<div class="PageInput__submitText" v-if="status == 1" @click="enterHandler">
					Check
					<i class="fas fa-arrow-right"></i>
				</div>

				<div class="PageInput__submitText success" v-if="status == 3">
					<span v-if="customValidationText">{{ customValidationText }}</span>
					<span v-if="!customValidationText">Done</span>
					<i class="fas fa-check"></i>
				</div>

				<div class="PageInput__submitText" v-if="status == 4"><i class="far fa-times-circle"></i> Error</div>
			</div>
		</template>


		<!-- Basic text input, with optional inline submission -->
		<template v-if="inputType == 'date'">

			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<input :min="min" :max="max"
					class="Page__Input" :type="inputType || 'text'" :placeholder="placeholderText" :value="internalInputModel" @input="onInputValueChange" :maxlength="maxlength"
					v-bind:class="{ 'PageInput__Loading': status == 2, 'PageInput__Success': status == 3, 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase, 'PageInput__Transparent': inputType == 'otp' }"
					@keyup.enter="enterHandler" ref="inputBox"
				/>
			</div>
		</template>


		<!-- Basic text input, with optional inline submission -->
		<template v-if="inputType == 'time'">

			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<input :min="min" :max="max"
					class="Page__Input" :type="inputType || 'text'" :placeholder="placeholderText" :value="internalInputModel" @input="onInputValueChange" :maxlength="maxlength"
					v-bind:class="{ 'PageInput__Loading': status == 2, 'PageInput__Success': status == 3, 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase, 'PageInput__Transparent': inputType == 'otp' }"
					@keyup.enter="enterHandler" ref="inputBox"
				/>
			</div>
		</template>


		<!-- Phone with country code (+44, etc) -->
		<template v-if="inputType == 'phone'">

		</template>


		<!-- One Time Passcode Input -->
		<!-- <template v-if="inputType == 'otp'">
			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<OtpInputComponent @onCompleted="otpHandler" v-bind:otp-state="otpState" />
		</template> -->


		<template v-if="inputType === 'searchableDropdown'">

			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputDropdownContainer" v-clicked-outside="onUnFocusDropdown" v-bind:class="{ 'open': isInputFocused }">
				<div class="Page__Input dropdown" @click="onFocusDropdown" v-bind:class="{ 'PageInput__Loading': status == 2, 'PageInput__Success': status == 3, 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase, 'PageInput__DropdownOpen': isInputFocused }">
					<input v-if="!dropdownItemSelected" ref="dropdownSearchInput"
						class="" type="text" :placeholder="placeholderText" @focus="onFocusDropdown" :value="internalInputModel" @input="onInputValueChange" :maxlength="maxlength"
						@keyup.enter="enterHandler" @keyup.esc="onUnFocusDropdown" />
					<div class="Page__DropdownSelectedItems" v-else>
						<strong>Selected: </strong>
						<div class="Page__Badge">{{ dropdownItemSelected.name }} <i class="fas fa-times"></i></div>
					</div>
				</div>
				<div class="PageInput__DropdownItemContainer" v-if="isInputFocused && filteredDropdownItems">
					<div class="Page__Text small PageInput__DropdownItem" v-if="internalInputModel && internalInputModel.length > 0 && filteredDropdownItems.length < 1">
						<strong><i class="fas fa-info-circle"></i> Info:</strong> It seems none of the available choices match your search criteria,
						please try again using a different phrase.
						<p class="Page__Text small">If you can't find the breed of your pet,
							you can select the "Other" option below</p>
					</div>
<!--					<div class="Page__Text small PageInput__DropdownItem" v-if="internalInputModel.length < 1">-->
<!--						<strong>Showing 5 out of 100 breeds</strong>, start typing to get more results-->
<!--					</div>-->
<!--					<div class="PageInput__DropdownItem Page__Flex" v-if="filteredDropdownItems.length < 1" @click="onDropdownSelectedItem(0)">-->
<!--						<div class="PageInput__DropdownItemIcon"></div>-->
<!--						<p class="PageInput__DropdownItemHeading Page__Text">Other/Not-Listed/Mixed</p>-->
<!--					</div>-->

					<div class="PageInput__DropdownItem Page__Flex M_Row" v-for="dropdownItem in filteredDropdownItems" @click="onDropdownSelectedItem(dropdownItem)">
						<div class="PageInput__DropdownItemIcon" v-bind:style="{ 'background-image': 'url(' + dropdownItem.image_url_s3 + ')' }"></div>
						<p class="PageInput__DropdownItemHeading Page__Text">{{ dropdownItem.name }}</p>
					</div>
				</div>
			</div>
		</template>

		<template v-if="inputType == 'checkbox'">
			<label class="Page__InputLabel Page__Checkbox">
				<input type="checkbox" :checked="internalInputModel" @click="onInputValueChange($event, 'checkbox')" true-value="true" false-value="false" />
				<span><slot name="label"></slot></span>
			</label>
		</template>


		<template v-if="inputType == 'textarea'">
			<span><slot name="label"></slot></span>
			<textarea class="Page__Input textarea" id="story" name="story" rows="5"  :placeholder="placeholderText" :value="internalInputModel" @input="onInputValueChange" :maxlength="maxlength">

			</textarea>
		</template>
	</div>
</template>

<script>
// import OtpInputComponent from "./OtpInput";

export default
{
	name: "Input.vue",
	// components: { OtpInputComponent },

	data()
	{
		return {
			internalInputModel: null,
			dropdownItemSelected: null,
			isInputFocused: false,
			input_width: 0
		}
	},

	props:
	{
		autocomplete: null,
		inputModel: null,
		inputType: String,
		placeholderText: String,
		maxlength: Number,
		inputTitle: String,
		initialStatus: Number,
		status: Number, /* 0: none, 1: submittable, 2: checking, 3: success, 4: failure */
		otpState: Number /* 0: none, 1: checking, 2: success, 3: failure */,
		customValidationText: String,
		basicTextLocalModel: String,
		capitalise: Boolean,
		lowercase: Boolean,

		/* Dropdown Input */
		dropdownItems: Array,
		overrideFocus: Boolean,

		/* Number Input */
		min: Number,
		max: Number
	},

	model:
	{
		prop: 'inputModel',
		event: 'input'
	},

	mounted()
	{
		this.internalInputModel = this.inputModel
	},

	methods:
	{
		onInputValueChange: function($event, type)
		{

			if (type == 'checkbox')
			{
				this.internalInputModel = $event.target.checked
				this.$emit('input', this.internalInputModel)
			}
			else
			{
				this.$emit('input', $event.target.value)

				if (!this.$listeners.input)
					this.internalInputModel = $event.target.value
			}

			if (!this.inputType || this.inputType == 'text' || this.inputType == 'number')
			{
				var input = this.$refs.inputBox;
				var c = document.createElement("canvas");
				var ctx = c.getContext("2d");
				ctx.font = this.font(input);
				var txtWidth = ctx.measureText(input.value).width;
				this.input_width = parseInt(txtWidth) + parseInt(window.getComputedStyle(input, null).getPropertyValue('padding-left').replace('px','')) + 10;
			}
		},

		font(element) {
			var prop = ["font-style", "font-variant", "font-weight", "font-size", "font-family"];
			var font = "";
			for (var x in prop)
				font += window.getComputedStyle(element, null).getPropertyValue(prop[x]) + " ";

			return font;
		},

		enterHandler: function(data)
		{
			if (this.status == 1)
			{
				this.$emit('enterEvent', this.inputModel)
			}
		},


		onFocusDropdown: function()
		{
			if (this.isInputFocused === false)
			{
				this.dropdownItemSelected = null
				this.isInputFocused = true

				this.$nextTick(function() { this.$refs.dropdownSearchInput.focus() })
				this.$emit('onFocus')
			}
		},


		onUnFocusDropdown: function()
		{
			this.$emit('onBlur')
			this.isInputFocused = false
		},


		onDropdownSelectedItem: function(event)
		{
			this.$emit('dropdownItemSelectedEvent', event)
			this.dropdownItemSelected = event
			this.onUnFocusDropdown()
		}
	},


	watch:
	{
		overrideFocus()
		{
			if (this.overrideFocus && !this.isInputFocused) this.onFocusDropdown()
			else if (!this.overrideFocus && this.isInputFocused) this.onUnFocusDropdown()
		},

		inputModel(newVal, oldVal)
		{
			this.internalInputModel = newVal
		}
	},

	computed:
	{
		hasFollowerText() {
			if (!this.$slots.followerText) return false;
			return !!this.$slots.followerText.length
		},
		filteredDropdownItems:
		{
			get()
			{
				if (this.dropdownItems == undefined)
					return undefined;

				let resultList = [];
				let none = null

				for (let i =0; i < this.dropdownItems.length; i++) {
					if (this.dropdownItems[i].name.toLowerCase().includes(this.internalInputModel.toLowerCase().trim()))
						resultList.push(this.dropdownItems[i]);

					if (this.dropdownItems[i]._id == process.env.VUE_APP_PET_NONE)
						none = this.dropdownItems[i]
				}
				console.log(none)
				console.log(process.env.VUE_APP_PET_NONE)
				if (resultList.length < 1 && none)
					resultList.push(none)

				return resultList;
			},

			set() {

			}
		}
	}
}
</script>

<style lang="scss">

	@import 'src/assets/scss/checkbox';
	@import 'src/assets/scss/_variables';

	.Page__Input
	{
		padding: 1px 13px;
		font-size: 13px;
		height: 38px;
		display: block;
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-transition: all 0.3s;
		transition: all 0.2s;
		outline: none;
		border-radius: var(--border_radius_main);
		box-shadow: 3px 3px 0px #46282824;
		background: #fff;
		border: 2px solid #ececf6;
		// border: 2px solid #3030568c;
		box-shadow: inset 0 0 5px #e8e8eb;
		outline: 2px solid transparent;

		&.dropdown
		{
			padding: 0;
			overflow: hidden;

			&>input
			{
				outline: none;
				padding: 1px 13px;
				width: 100%;
				height: 100%;
				border: none;
				font-size: 12px;
			}

			&>.Page__DropdownSelectedItems
			{
				padding: 7px 14px;
				cursor: pointer;
			}
		}

		&.PageInput__DropdownOpen
		{
			box-shadow: none;
			border-radius: 4px 4px 0px 0px;
		}

		&.textarea
		{
			padding-top: 10px;
			height: 100px;
			width: 100% !important;
		}

		&.PageInput__Transparent
		{
			opacity: 0;
		}
	}

	.Page__InputDropdownContainer
	{
		position: relative;

		&:before
		{
			content: " ";
			position: absolute;

			height: 12px;
			width: 12px;
			background-color: inherit;
			border: inherit;
			right: 20px;
			top: 9px;
			clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
			transform: rotate(-45deg);
			border-radius: 0.25em 0.25em 0.25em 0.25em;
			background: #0003;
		}

		&.open:before
		{
			top: 15px;
			transform: rotate(135deg);
		}

		.PageInput__DropdownItemContainer
		{
			position: absolute;
			width: 100%;
			z-index: 3;
			max-height: 170px;
			overflow-y: scroll;
			border-radius: 0px 0px 4px 4px;
			border: 1px solid #00000052;
			border-top: none;
			background: #fff;

			.PageInput__DropdownItem
			{
				padding: 13px 13px;
				align-items: center;
				cursor: pointer;

				&:hover
				{
					background: #0000000a;
				}

				.PageInput__DropdownItemIcon
				{
					width: 30px;
					height: 30px;
					background: #0000000a;
					border-radius: 25px;
					margin-right: 10px;

					background-size: cover !important;
				}

				.PageInput__DropdownItemHeading
				{
					font-size: 0.85em;
				}
			}
		}
	}


	.Page__Input:focus, .Page__Input:hover
	{
		outline: 2px solid #2f3ec1;
	}

	.Page__InputContainer
	{
		width: 100%;
		position: relative;

		.Page__InputLabel
		{
			font-size: 13px;
			margin-bottom: 4px;
			display: inline-block;

			i { font-size: 12px; }
		}
	

		.Page__InputInputContainer
		{
			position: relative;

			.Page__InputOTPOVerlay
			{
				position: absolute;
				left: 0%;
				right: 0;
				top: 0;
				display: flex;
				gap: 10px;

				&>div
				{
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.Page__InputFollowerText
			{
				color: #0008;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0%, -50%);
			}
		}
	}

	.Page__Input_StateContainer
	{
		position: absolute;
		right: 18px;
		top: 52%;
		color: #707173;
	}

	.PageInput__submitText
	{
		color: #676767;
	}

	.PageInput__Loading
	{
		background: #eee;
		cursor: no-drop;
		pointer-events: none;
	}

	.PageInput__submitText.success
	{
		color: green;
	}

	.PageInput__Success
	{
		background: #74a2741c;
	}

	.PageInput__Capitalise { text-transform: capitalize; }
	.PageInput__Lowercase { text-transform: lowercase; }




.Page__Input
    {
        padding: 8px 40px 8px 9px;
        width: 100%;
        border-radius: var(--border_radius_main);
        border: 1px solid var(--border_color_input);
        background: var(--background_color_main);
        font-size: var(--font_size_small);
		box-shadow: inset 0 0 7px 1px #1d16460d;
        letter-spacing: 0.3px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
    {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }


    input.Page__Input:disabled
    {
        background: var(--text_color_disabled);
    }

    .Page__InputLabel
    {
        margin-bottom: 3px;
        display: block;
        font-size: 13px;
    }


    .Page__InputField
    {
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .Page__InputError
    {
        color: var(--background_color_danger);
        font-size: 11px;
        letter-spacing: 0.3px;
        margin-top: 5px;
    }


	.PageInput__DropdownOpen
	{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.Page__InputDropdownContainer 
	{
		position: relative;

		&::after
		{
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;

			font-family: "Font Awesome 6 Pro";
    		font-weight: 300; 
			font-size: 16px;
			content: "\f0d7";
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}

		&.open::after
		{
			transform: translateY(-50%) rotate(180deg);
		}

		&.hasSelected:not(.open)
		{
			.Page__Input 
			{
				padding: 5px 40px 5px 9px;
			}
		}
	}

	.PageInput__DropdownItemContainer
	{
		position: absolute;
		z-index: 4;
		left: 0;
		right: 0;
		background: var(--background_color_main);

		width: 100%;
		border-radius: var(--border_radius_main);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
        border: 1px solid var(--border_color_input);
		border-top: 0;

        background: var(--background_color_main);
        font-size: 10px;
        letter-spacing: 0.3px;

		max-height: 190px;
		overflow-y: scroll;

		.PageInput__DropdownItemIcon
		{
			background: var(--background_color_hero);
			height: 20px;
			width: 20px;
			border-radius: 25px;
			margin-right: 7px;
			vertical-align: middle;

			&.selected
			{
				background: var(--background_color_discord);
			}
		}

		.PageInput__DropdownItem, .Page__DropdownSelectedItemsInner
		{
			padding: 10px 8px;
			vertical-align: middle;
			border-radius: var(--border_radius_main);

			cursor: pointer;

			&:hover
			{
				background: var(--background_color_hero);
			}
		}

		.Page__DropdownSelectedItemsInner
		{
			border-radius: 0;
			border-bottom: 1px solid var(--border_color_input);
			gap: 6px;

			.Page__Badge
			{
				padding: 0px 8px;
				padding-top: 1px;
				font-size: var(--page_text_small);
			}
		}
	}

	.Page__DropdownSelectedItems 
	{
		strong
		{
			font-size: var(--font_size_small);
			text-transform: lowercase;
		}

		.Page__Badge
		{
			padding: 0px 8px;
			padding-top: 1px;
			font-size: 10px;
		}
	}


	.Page__Button
	{
		padding: 10px 16px;
		border-radius: var(--border_radius_main);
		color: var(--color_text_contrast);
		background: var(--color_background_button);
		border: none;
		cursor: pointer;

		&.loading
		{
			background: var(--border_color_input);
			color: var(--color_text_main);
		}
		
	}


</style>